<template>
    <section>
        <div id="filter-applicant" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <button
                    class="uk-modal-close-default"
                    type="button"
                    uk-close
                    @click="hideModal(false)"
                ></button>
                <div class="uk-modal-header">
                    <h4>Filter Applicant</h4>
                </div>
                <div class="uk-modal-body">
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="
                                        checkFilterApplicant.is_job_preference
                                    "
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                Job Preference</label
                            >
                            <multiselect
                                v-model="applicantFilter.job_preferences"
                                :multiple="true"
                                placeholder="Select Preferensi"
                                label="name"
                                name="job_preference"
                                track-by="name"
                                :searchable="true"
                                :options="
                                    filterOptions.job_preference_options
                                "
                                :loading="isFetching"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="
                                    !checkFilterApplicant.is_job_preference
                                "
                            >
                            </multiselect>
                        </div>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="checkFilterApplicant.is_domicile"
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                Domicile</label
                            >
                            <multiselect
                                v-model="applicantFilter.domicilies"
                                :multiple="true"
                                placeholder="Type to search city"
                                label="name"
                                name="city"
                                track-by="name"
                                :searchable="true"
                                :options="filterOptions.city_options"
                                :loading="isFetching"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :show-no-results="false"
                                @search-change="getCityOptions"
                                :disabled="!checkFilterApplicant.is_domicile"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="checkFilterApplicant.is_education"
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                Education</label
                            >
                            <multiselect
                                v-model="applicantFilter.education_levels"
                                :multiple="true"
                                placeholder="Select Pendidikan"
                                label="name"
                                name="education"
                                track-by="name"
                                :searchable="true"
                                :options="filterOptions.education_options"
                                :loading="isFetching"
                                :internal-search="true"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="!checkFilterApplicant.is_education"
                            >
                            </multiselect>
                        </div>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="
                                        checkFilterApplicant.is_work_experience
                                    "
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                Work experience</label
                            >
                            <input
                                v-model="applicantFilter.work_experiences"
                                class="uk-input"
                                placeholder="Type to filter work exprerience"
                                :disabled="
                                    !checkFilterApplicant.is_work_experience
                                "
                            />
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    disabled
                                    v-model="checkFilterApplicant.is_age"
                                    class="uk-checkbox"
                                    type="checkbox"
                                />{{ ' ' }}Age</label
                            >
                            <multiselect
                                v-model="applicantFilter.age"
                                :multiple="false"
                                placeholder="Select Age"
                                :searchable="false"
                                :options="[
                                    '<20',
                                    '21-30',
                                    '31-40',
                                    '41-50',
                                    '>50'
                                ]"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="!checkFilterApplicant.is_age || true"
                            />
                        </div>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="checkFilterApplicant.is_gender"
                                    class="uk-checkbox"
                                    type="checkbox"
                                />{{ ' ' }}Gender</label
                            >
                            <multiselect
                                v-model="applicantFilter.gender"
                                :multiple="false"
                                placeholder="Select Gender"
                                track-by="value"
                                label="name"
                                :searchable="false"
                                :options="[
                                    { name: 'Laki-laki', value: 'male' },
                                    { name: 'Perempuan', value: 'female' }
                                ]"
                                :loading="isFetching"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="!checkFilterApplicant.is_gender"
                            >
                            </multiselect>
                        </div>
                    </div>
                    <div uk-grid>
                        <div class="uk-width-1-2">
                            <label
                                ><input
                                    v-model="
                                        checkFilterApplicant.is_applicant_status
                                    "
                                    class="uk-checkbox"
                                    type="checkbox"
                                />
                                Applicant Status</label
                            >
                            <multiselect
                                v-model="applicantFilter.applicant_status"
                                :multiple="false"
                                placeholder="Select applicant status"
                                track-by="value"
                                label="name"
                                :searchable="false"
                                :options="applicantStatusOptions"
                                :loading="isFetching"
                                :internal-search="false"
                                :clear-on-select="false"
                                :close-on-select="false"
                                :disabled="
                                    !checkFilterApplicant.is_applicant_status
                                "
                            >
                            </multiselect>
                        </div>
                        <div class="uk-width-1-2 uk-flex uk-flex-column">
                            <div class="uk-flex uk-flex-row">
                                <label class="uk-width-1-3"
                                    ><input
                                        v-model="applicantFilter.has_doc_ktp"
                                        class="uk-checkbox"
                                        type="checkbox"
                                    />
                                    KTP</label
                                >
                                <label class="uk-width-1-3"
                                    ><input
                                        v-model="applicantFilter.has_doc_sim"
                                        class="uk-checkbox"
                                        type="checkbox"
                                    />
                                    SIM</label
                                >
                                <label class="uk-width-1-3"
                                    ><input
                                        v-model="applicantFilter.has_doc_skck"
                                        class="uk-checkbox"
                                        type="checkbox"
                                    />
                                    SKCK</label
                                >
                            </div>
                            <div class="uk-flex uk-flex-ro">
                                <label class="uk-width-1-3"
                                    ><input
                                        v-model="applicantFilter.has_doc_kk"
                                        class="uk-checkbox"
                                        type="checkbox"
                                    />
                                    KK</label
                                >
                                <label class="uk-width-1-3"
                                    ><input
                                        v-model="applicantFilter.has_doc_ijazah"
                                        class="uk-checkbox"
                                        type="checkbox"
                                    />
                                    IJAZAH</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="uk-modal-footer uk-flex uk-flex-middle uk-flex-between"
                >
                    <button
                        class="uk-button uk-button-default clear-button"
                        type="button"
                        @click="clearFilter"
                    >
                        Clear Filter
                    </button>
                    <button
                        class="uk-button uk-button-primary apply-button"
                        type="button"
                        :disabled="!buttonApplyFilter"
                        @click="applyFilter"
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
    name: 'FilterApplicantModal',
    components: {
        Multiselect
    },
    props: {
        applicantFilter: {
            type: Object,
            required: true
        },
        checkFilterApplicant: {
            type: Object,
            required: true
        },
        buttonApplyFilter: {
            type: Boolean,
            required: true
        },
        isFetching: {
            type: Boolean,
            required: true
        },
        filterOptions: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            applicantStatusOptions: [
                {
                    name: 'New Applicant',
                    value: '0'
                },
                {
                    name: 'Picked up Applicant',
                    value: 1
                },
                {
                    name: 'Invited to Interview',
                    value: 4
                },
                {
                    name: 'Interviewed Applicant',
                    value: 5
                },
                {
                    name: 'Contract Sent',
                    value: 6
                },
                {
                    name: 'Signed Applicant',
                    value: 7
                },
                {
                    name: 'Rejected',
                    value: 3
                }
            ]
        };
    },
    mounted() {
        window.UIkit.modal('#filter-applicant').show();
    },
    methods: {
        clearFilter() {
            this.$emit('clear-filter');
            this.hideModal();
        },
        applyFilter() {
            this.$emit('apply-filter');
            this.hideModal();
        },
        hideModal() {
            window.UIkit.modal('#filter-applicant').$destroy(true);
            this.$emit('hideModal');
        },
        getCityOptions(query) {
            this.$emit('get-city-options', query);
        }
    }
};
</script>

<style scoped>
.clear-button {
    border: 1px solid #ef5350;
    color: #ef5350;
}
.clear-button:disabled {
    color: #979797 !important;
    border: 1px solid #e5e5e5;
}
.apply-button:disabled {
    color: #979797 !important;
}
</style>
